// dashboard.scss
.dashboard-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 20px;
  background-color: var(--dashboard-blue);
  height: 75px;
  width: 100%;
}

.logo-and-menu {
  display: flex;
  align-items: center;
}

.dashboard-logo {
  width: 50px;
  height: auto;
  cursor: pointer;
  margin-right: 10px;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 28px;
  cursor: pointer;
}

.dashboard-button {
  margin-top: 0;
  text-align: center;
  font-weight: bold;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  height: max-content;

  &:hover {
    background-color: var(--dashboard-hover-blue);
  }
}

.navigation-buttons {
  display: flex;
  align-items: center;

  .dashboard-button:not(:last-child) {
    margin-right: 15px;
  }
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .dashboard-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--dashboard-blue);
    height: auto;
    

  }

  .dashboard-button {
    font-size: 1.2em;
  }

  .menu-icon {
    display: block;
  }

  .navigation-buttons {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    background-color: var(--dashboard-blue);
    flex-direction: column;
    align-items: center;
    display: none;
    z-index: 1000;

    &.active {
      display: flex;
    }

    .dashboard-button {
      width: 100%;
      padding: 15px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      &:first-child {
        border-top: none;
      }
    }
  }

  .logout-button {
    margin-left: 0;
  }
}