.sidenav-wrapper {
  width: 100%;
}

/* Add styles for the search icon */
.search-icon {
  font-size: 2.3rem;
  cursor: pointer;
  color: var(--branco);
}

.search-input {
  font-size: 1rem;
  color: var(--preto);
  background-color: var(--branco);
  border: 1px solid var(--cinza-claro);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  width: 200px;
  transition: all 0.3s ease-in-out;

  &:focus {
    outline: 1px solid var(--azul); /* Borda azul ao focar */
    caret-color: var(--preto); /* Define a cor do cursor */
  }
}


.sidenav {
  font-family: "Contrail One", cursive;
  font-size: 1.475em;
  height: 5vw;
  width: 100vh;
  position: sticky;
  background-color: var(--preto);
  color: var(--branco);
  z-index: 510;
  top: 0;
  left: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;

  // Estilos específicos para desktop
/* Estilos específicos para desktop */
@media (min-width: 769px) {
  position: fixed;
  top: 0;
  left: 0;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
}


  // Estilos específicos para mobile
  @media (max-width: 768px) {
    transform: none;
    transform-origin: initial;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 7.5vh; // ajuste conforme necessário
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--preto);
    z-index: 510;
    overflow: visible;

    .sidenav {
      display: flex;
      align-items: center;
      justify-content: space-between; /* Espaça logo e ícones nas extremidades */

    }
    .icons {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .logo {
      display: flex;
      align-items: center;
      color: #fff;
      text-decoration: none;
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--branco);
  text-decoration: none;
  width: 30%;
  overflow: hidden;
  cursor: pointer;
  background-color: var(--preto);
  transform: skewX(-15deg);
  transition: all 0.4s;

  span {
    transform: skewX(15deg);
    z-index: 510;
  }

  &:hover {
    color: var(--preto);
  }

  &:after {
    border: none;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: var(--branco);
    transition: all 0.35s;
  }

  &:hover:after {
    width: 100%;
  }
}

#logo {
  transform: rotate(90deg);
  width: 10%;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    transform: rotate(0deg);
    width: auto;
    padding: 0 1em;
    margin-bottom: 0;
  }
}

.hamburger-icon {
  font-size: 2em; /* tamanho base */
  transform: scale(1.01); /* escala para aumentar o tamanho */
  transform-origin: center;
  margin-right: 0.5em;
  cursor: pointer;
  overflow: visible;
  @media (min-width: 769px) {
    display: none;
    
  }
}

.mobile-menu {
  position: absolute;
  top: 7vh; /* Adjust to match the height of your navbar */
  left: 0;
  width: 100%;
  background-color: var(--preto);
  display: flex;
  flex-direction: column;
  z-index: 999;

  /* Ensure that the text does not wrap and is centered */
  a {
    padding: 1em 0.5em; /* Adjust padding as needed */
    text-align: center;
    border-bottom: 1px solid var(--branco);
    color: var(--branco);
    text-decoration: none;
    white-space: nowrap;        /* Prevent text from wrapping */
    overflow: hidden;           /* Hide overflow if text is too long */
    text-overflow: ellipsis;    /* Add ellipsis (...) if text overflows */
    width: 100%;                /* Make the link span the full width */
    display: flex;
    justify-content: center;    /* Center the content horizontally */
    align-items: center;        /* Center the content vertically */
  }

  /* Optional: Remove the bottom border from the last menu item */
  a:last-child {
    border-bottom: none;
  }
}


.subnavs {
  // estilos existentes
}
