@import url("https://fonts.googleapis.com/css2?family=Contrail+One&display=swap");
@import url("https://use.typekit.net/aey1zjh.css");

*,
html,
body {
  scroll-behavior: smooth !important;
  scrollbar-width: none;
}


body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  caret-color: transparent;
  background-color: var(--background-light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-rosa {
  background-color: var(--rosa) !important;
}

.bg-azul {
  background-color: var(--azul) !important;
}

.bg-verde {
  background-color: var(--verde) !important;
}

.bg-branco {
  background-color: var(--branco) !important;
}

.color-b {
  color: var(--preto) !important;
}

.color-w {
  color: var(--branco) !important;
}

:root {
  --azul: #27fdfd;
  --roxo: #5c1c74;
  --verde: #e0ff04;
  --rosa: #cc1988;
  --laranja: #fb7800;
  --preto: #000;
  --branco: #fff;
  --dashboard-blue: #007bff;
  --dashboard-hover-blue: #003d80;
  --background-light: #f5f5f5;

  --gray1: #121212;
  --gray2: #1e1e1e;
  --gray3: #242424;
  --gray4: #2c2c2c;
}

.quill > .ql-toolbar:first-child {
  display: none !important;
}

input {
  width: 20vw;
  font-size: large;
  margin-top: 0.5vh;
}

button {
  cursor: pointer;
  margin-top: 1vh;
  padding: 1vh 2vw;
}

.title {
  margin-bottom: 1vh;
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
}
