@keyframes pixelRise {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, -150px, 0);
    opacity: 0;
  }
}

.pixels-container {
  width: 100%;
  height: 100px;
  position: relative;
  overflow: hidden;
  padding-top: 200px; // Ajuste conforme necessário

  // Ajustes para telas menores
  @media (max-width: 600px) {
    height: 60px;
    padding-top: 100px;
  }
}

.pixel {
  position: absolute;
  bottom: 0;
  width: 20px;
  height: 20px;
  animation: pixelRise 3s infinite ease-in;
  animation-fill-mode: forwards;

  @media (max-width: 600px) {
    width: 10px;
    height: 10px;
  }
}
