/* Register.scss */
.Register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-light);
  padding: 20px; /* Adicionado para evitar que o conteúdo encoste nas bordas em dispositivos móveis */
}

.register-card {
  background-color: #ffffff;
  padding: 40px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;

  .title {
    margin-bottom: 30px;
    text-align: center;
    color: #333;
    font-size: 24px; /* Ajustado para melhor legibilidade */
  }

  .input-wrapper {
    width: 100%; /* Ajustado para ocupar toda a largura */
    margin-bottom: 20px;

    label {
      margin-bottom: 5px;
      color: #666;
      display: block;
    }

    input {
      width: 100%; /* Garante que o input ocupe toda a largura disponível */
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  .register-button {
    width: 100%; /* Ocupa toda a largura disponível */
    padding: 12px; /* Aumentado para melhorar a área de toque */
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 16px; /* Aumentado para melhor legibilidade */

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .register-card {
    padding: 20px;
    margin: 20px 0;
    margin-top: 70px;
  }

  .title {
    font-size: 20px; /* Reduzido para caber melhor em telas menores */
  }

  .register-button {
    padding: 14px; /* Aumenta a área de toque em dispositivos móveis */
    font-size: 18px; /* Aumenta o tamanho da fonte para melhor legibilidade */
  }
}
