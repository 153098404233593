@font-face {
  font-family: regular-myriad;
  src: URL(../../fonts/myriad-pro-regular.ttf) format("truetype");
}

.no-underline {
  text-decoration: none;
}

.bg-img-rosa {
  background-image: url(./Assets/fundo-rosa.gif);
  background-position: center;
  background-size: cover;
}

.bg-img-verde {
  background-image: url(./Assets/fundo-verde.gif);
  background-position: center;
  background-size: cover;
}

.bg-img-azul {
  background-image: url(./Assets/fundo-azul.gif);
  background-position: center;
  background-size: cover;
}

section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .letreiro {
    display: flex;
    width: 20%;
    height: 100%;
    padding-left: 2%;
    padding-right: 5%;

    .imagem {
      max-width: 100%;
      height: auto;
    }
  }

  .meio {
    display: flex;
    flex-flow: column nowrap;
    width: 40%;
    height: 100%;

    .titulo {
      height: 40%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      transform: translateY(-500px);

      img {
        width: 80%;
      }
    }

    .div-texto {
      font-family: regular-myriad, sans-serif;
      font-size: 1.4vw;
      letter-spacing: 0.15em;
      font-style: normal;
      text-align: left;
      margin: 10%;
      padding: 3%;

      .preto {
        color: var(--preto);
        font-weight: 600;
      }

      .branco {
        color: var(--branco);
        font-weight: 500;
      }
    }

    .div-txt-verde {
      background-color: rgba(217, 255, 79, 0.74);
    }

    .div-txt-rosa {
      background-color: rgba(0, 0, 0, 0.445);
    }

    .div-txt-azul {
      background-color: rgba(78, 249, 255, 0.651);
    }
  }

  .direita {
    display: flex;
    width: 30%;
    height: 100%;
  }

  #controls {
    width: 400px;
    height: 200px;
    padding: 20px;
    margin: 50px auto;
    background: purple;
  }

  .entrou-na-tela {
    max-width: 100%;
    height: auto;
    animation: slideIn 1s forwards;
  }

  .direita-polaroid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .polaroid-topo {
    align-self: center;
    width: 100%;
    height: 40%;
    background-image: url(./Assets/polaroid-top.png);
    background-size: contain;
    background-repeat: no-repeat;
    animation: polaroidSlideIn 2.5s forwards;
    margin-top: 5px;
  }

  .polaroid-topo:hover {
    background-image: url(./Assets/polaroid-top-hover.png);
    cursor: pointer;
  }

  .polaroid-meio {
    align-self: center;
    width: 100%;
    height: 40%;
    background-image: url(./Assets/polaroid-mid.png);
    background-size: contain;
    background-repeat: no-repeat;
    animation: polaroidSlideIn 3.5s forwards;
    margin-left: 250px;
  }

  .polaroid-meio:hover {
    background-image: url(./Assets/polaroid-mid-hover.png);
    cursor: pointer;
  }

  .polaroid-baixo {
    align-self: center;
    width: 100%;
    height: 40%;
    background-image: url(./Assets/polaroid-bot.png);
    background-size: contain;
    background-repeat: no-repeat;
    animation: polaroidSlideIn 3.5s forwards;
    margin-bottom: 10px;
  }

  .polaroid-baixo:hover {
    background-image: url(./Assets/polaroid-bot-hover.png);
    cursor: pointer;
  }

  .mao {
    align-self: last baseline;
    display: flex;
    justify-content: space-between;
    padding-top: 15%;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80%;
    padding-bottom: 12vh;
    animation: roloSlide 1s forwards;
    background-image: url(./Assets/filme.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    text-decoration: none;

    a:hover {
      color: var(--rosa);
      cursor: pointer;
    }

    .mao-1 {
      min-height: 3em;
    }

    .mao-2 {
      min-height: 3em;
    }

    .mao-3 {
      min-height: 4.5em;
    }

    .mao-4 {
      min-height: 3em;
    }
  }

  .hide {
    opacity: 0;
  }

  .texto-mao {
    font-family: "Contrail One", cursive;
    text-align: start;
    font-size: 1.5vw;
    overflow: hidden;
    color: black;
    max-width: 40%;
    margin: 0 0 0em 3.5vw;
    opacity: 1;
    width: 100%;
  }

  .fita-direita {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-image: url(./Assets/fita-menu-direita.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    animation: polaroidSlideIn 2s forwards;

    .fita-direita-1 {
      align-self: flex-start;
      width: 70%;
      height: 15%;
      background-image: url(./Assets/fita-1.png);
      background-size: contain;
      background-repeat: no-repeat;
      animation: polaroidSlideIn 2.5s forwards;
    }

    .fita-direita-1:hover {
      background-image: url(./Assets/fita-1-hover.png);
      cursor: pointer;
    }

    .fita-direita-2 {
      align-self: flex-end;
      margin-right: 7vw;
      width: 65%;
      height: 17%;
      background-image: url(./Assets/fita-2.png);
      background-size: contain;
      background-repeat: no-repeat;
      animation: polaroidSlideIn 3s forwards;
    }

    .fita-direita-2:hover {
      background-image: url(./Assets/fita-2-hover.png);
      cursor: pointer;
    }

    .fita-direita-3 {
      align-self: center;
      width: 50%;
      height: 14%;
      background-image: url(./Assets/fita-32.png);
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 4vw;
      animation: polaroidSlideIn 3.25s forwards;
    }

    .fita-direita-3:hover {
      background-image: url(./Assets/fita-3-hover.png);
      cursor: pointer;
    }

    .fita-direita-4 {
      align-self: center;
      width: 70%;
      height: 17%;
      margin-right: 7vw;
      background-image: url(./Assets/fita-4.png);
      background-size: contain;
      background-repeat: no-repeat;
      animation: polaroidSlideIn 3.75s forwards;
      margin-bottom: 10vh;
    }

    .fita-direita-4:hover {
      background-image: url(./Assets/fita-4-hover.png);
      cursor: pointer;
    }
  }

  @keyframes polaroidSlideIn {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes roloSlide {
    0% {
      transform: translateY(1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-200px);
    }
    100% {
      transform: translateY(500px);
    }
  }
}

.no-underline {
  text-decoration: none;
}

/* Ajuste global para elementos de texto */
.div-texto p {
  margin-bottom: 5%;
}

.entrou-na-tela {
  max-width: 100%;
  height: auto;
  animation: slideIn 1s forwards;
}

.hide {
  display: none;
}

/* CSS para Mobile */
@media only screen and (max-width: 768px) {
  .marquee {
    overflow: visible;
  }

  section {
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 2% 5%;
    height: 100vh;

    .letreiro-mobile-container {
      width: 20%;
      height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10%;
      transform: rotate(90deg);

      .letreiro-mobile {
        width: 100%;

        .imagem {
          max-width: 100%;
          height: auto;
        }
      }
    }

    .meio {
      width: 100%;
      transform: translateY(-80px);

      .titulo {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(0);
        margin-bottom: 2vw;

        img {
          width: 95%;
          height: auto;
        }
      }

      .div-texto {
        font-size: 4vw;
        line-height: 1.5;
        text-align: center;
        padding: 1%;
        margin: 0;
        border-radius: 5px;
      }
    }

    .direita {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mao {
      width: 100%;
      height: auto;
      padding: 10% 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      a {
        text-align: center;
        font-size: 4vw;
      }
    }

    .texto-mao {
      max-width: 90%;
      font-size: 4vw;
      margin: 2% 0;
    }

    .mobile-menu-container1 {
      position: absolute;
      left: 0;
      bottom: 35vw; /* Ajuste a altura conforme necessário */
      width: 85vw;
      height: 30vw;
      display: flex;
      align-items: center;
      justify-content: space-evenly; /* Itens igualmente espaçados */
      background-image: url(./Assets/menu-mobile.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 10px;
      box-sizing: border-box;
    }

    .mobile-menu-container11 {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 90vw;
      height: 30vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly; /* Itens igualmente espaçados */
      background-image: url(./Assets/menu-mobile.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 10px;
      box-sizing: border-box;
      transform: rotate(-180deg);
    

      .mobile-menu-item11 {
        width: 40%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(180deg);
        color: #fff;
        text-decoration: none;
        font-size: 3.8vw;
        font-weight: bold;
        font-style: italic;
        text-align: center;
        box-sizing: border-box;
        line-height: 1.2;
        padding-bottom: 3vw;
        padding-left: 1.5vw;
        a {
          display: inline-block;
          width: 100%;
          text-align: center;
          text-decoration: none;
          color: #0a0a0a;
          font-size: inherit;
        }
        a[href="#nossa-galera"]{
          padding-right: 4.5vw;

        }
      }
    }

    .mobile-menu-item {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      text-decoration: none;
      font-size: 3.8vw;
      font-weight: bold;
      font-style: italic;
      text-align: center;
      box-sizing: border-box;
      line-height: 1.2;
      padding-top: 3vw;
      padding-left: 4.5vw;
      a {
        display: inline-block;
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: #0a0a0a;
        font-size: inherit;

      }
      a[href="#sobre-nos"]{
        padding-left: 4.5vw;

      }
    }

    .mobile-menu-item:hover {
      text-decoration: underline;
    }

    .mobile-menu-container2 {
      display: flex;
      align-items: flex-start;
      justify-content: space-around;
      width: 100%;
      padding: 10px;
      gap: 3vw;
      margin-top: -20vw;
    }

    .polaroid-topo,
    .polaroid-meio,
    .polaroid-baixo {
      width: 30vw;
      height: 30vw;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .polaroid-topo {
      background-image: url(./Assets/polaroid-top.png);
    }

    .polaroid-meio {
      background-image: url(./Assets/polaroid-mid.png);
      margin-bottom: 25vw;
      margin-left: 2vw;
    }

    .polaroid-baixo {
      background-image: url(./Assets/polaroid-bot.png);
    }

    .polaroid-topo:hover {
      background-image: url(./Assets/polaroid-top-hover.png);
      cursor: pointer;
    }

    .polaroid-meio:hover {
      background-image: url(./Assets/polaroid-mid-hover.png);
      cursor: pointer;
    }

    .polaroid-baixo:hover {
      background-image: url(./Assets/polaroid-bot-hover.png);
      cursor: pointer;
    }

    .mobile-menu-container3 {
      transform: rotate(90deg);
    }

    .fita-direita {
      width: 90vw;
      height: 70vw;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: -10vw;
      align-items: center;
      justify-items: center;
      justify-content: center;
      margin-bottom: 50px;
      margin-right: 90px;
      background-image: none;

      .fita-direita-3,
      .fita-direita-1 {
        width: 40vw;
        height: 20vh;
        margin-bottom: -50px;
        box-sizing: border-box;
        transform: rotate(-90deg) !important;
      }

      .fita-direita-2,
      .fita-direita-4 {
        width: 40vw;
        height: 20vh;
        box-sizing: border-box;
        transform: rotate(-90deg) !important;
        margin-bottom: -20vw;
        margin-right: 100px;
        margin-left: -50px;
      }

      .fita-direita-2 {
        margin-bottom: 5px;
      }
    }

    .fita-direita-1 {
      background-image: url(./Assets/fita-1.png);
    }

    .fita-direita-2 {
      background-image: url(./Assets/fita-2.png);
    }

    .fita-direita-3 {
      background-image: url(./Assets/fita-3.png);
    }

    .fita-direita-4 {
      background-image: url(./Assets/fita-4.png);
    }

    .fita-direita-1:hover {
      background-image: url(./Assets/fita-1-hover.png);
      cursor: pointer;
    }

    .fita-direita-2:hover {
      background-image: url(./Assets/fita-2-hover.png);
      cursor: pointer;
    }

    .fita-direita-3:hover {
      background-image: url(./Assets/fita-3-hover.png);
      cursor: pointer;
    }

    .fita-direita-4:hover {
      background-image: url(./Assets/fita-4-hover.png);
      cursor: pointer;
    }

    @keyframes slideIn {
      0% {
        transform: translateY(-50px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes polaroidSlideIn {
      0% {
        transform: translateY(100px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes roloSlide {
      0% {
        transform: translateY(100px);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes slideHorizontal {
      0% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(100%);
      }
    }

    .mobile-animacao {
      animation: slideHorizontal 9s linear infinite;
    }
  }
}

@media only screen and (max-width: 768px) {
  .mobile-menu-chega {
    display: flex;
    justify-content: space-evenly; /* Places columns side by side */
    align-items: flex-start;
    gap: 3vw; /* Horizontal spacing between columns */
    margin-top: -40vw; /* Adjust as needed */
    padding-bottom: 20vw;
  }

  /* Each vertical purple strip container */
  .fita-roxa-coluna {
    /* Remove the background image from here */
    position: relative; /* Needed for the pseudo-element */
    
    /* Layout settings */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
    /* Define sensible dimensions for your layout */
    width: 60vw;   /* Adjust as needed */
    height: 30vw;  /* Adjust as needed */
    
    /* (Optional) set a background color if needed */
    background-color: transparent;
  }

  /* Create the pseudo-element to hold and rotate the background image */
  .fita-roxa-coluna::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    /* Background image properties */
    background-image: url('./Assets/roxa.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
    /* Rotate the background image 90 degrees to the right */
    transform: rotate(90deg);
    transform-origin: center;
    
    /* Ensure the pseudo-element stays behind the content */
    z-index: 0;
    
    /* Optional: if the rotated image appears cut off,
       you may need to adjust its positioning using translate. For example:
       transform: rotate(90deg) translateX(50%);
       Adjust values as necessary.
    */
  }

  /* Basic styling for the links */
  .fita-roxa-coluna a {
    display: block;
    text-align: center;
    text-decoration: none;
    background-color: #fff;
    color: #000;
    font-size: 3.7vw;
    font-weight: bold;
    font-style: italic;
    padding: 4vw;
    width: 100%; /* So that it fits well inside the column */
    margin: 0;
    
    /* If you want to use clip-path for curves, adjust here */
    clip-path: polygon(
      2% 15%,
      99% 15%,
      100% 85%,
      60% 84.5%,
      1% 85%
    );
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    
    transition: background-color 0.3s, color 0.3s;
  }

  .fita-roxa-coluna a:hover {
    background-color: #ddd; /* Change color on hover */
    text-decoration: underline;
  }
}