.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.edit-edition {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;

  .title {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }

  .input-wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 5px;
      color: #666;
      font-weight: bold;
    }

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  .editor {
    margin-bottom: 20px;
    height: 300px;

    .ql-toolbar {
      border-radius: 4px 4px 0 0;
    }

    .ql-container {
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      height: 100%;
    }
  }

  .photo-section {
    margin-top: 20px;
    margin-bottom: 20px;

    .photos-title {
      margin-bottom: 10px;
      color: #666;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .photo-gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      position: relative;

      .photo-thumbnail {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 150px;
          height: 150px;
          border-radius: 4px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          object-fit: cover;
        }

        .delete-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 50%;
          visibility: visible;
        }

        &.add-photo {
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: #f8f9fa;
          border: 2px dashed #ccc;

          svg {
            font-size: 48px;
            color: #007bff;
          }

          input {
            display: none;
          }

          &:hover {
            background-color: #e9ecef;
            border-color: #007bff;
          }
        }
      }

      .toggle-delete-icon {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 10;
      }
    }
  }

  .save-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

.modal-box {
  position: fixed; /* Alterado de absolute para fixed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
  max-width: 90%; /* Ajustado para responsividade */
  text-align: center;
  z-index: 1000; /* Adicionado para garantir que o modal fique acima de outros elementos */

  .preview-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .preview-image {
      max-width: 100%;
      max-height: 300px;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      object-fit: cover;
    }
  }

  .confirm-button {
    margin-right: 10px;
  }

  .cancel-button {
    background-color: #f44336;

    &:hover {
      background-color: #d32f2f;
    }
  }
}

.add-new-edition {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  margin-top: 20px;
  width: 100%;
  max-width: 800px;

  input[type="file"],
  input[type="text"] {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  button:hover {
    background-color: #0056b3;
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .edit-edition {
    width: 95%;
    padding: 15px;
  }

  .photo-section .photos-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .photo-gallery {
    justify-content: center;
    gap: 10px;

    .photo-thumbnail img {
      width: 100px;
      height: 100px;
    }
  }

  .editor {
    height: auto;

    .ql-container {
      height: 200px;
    }
  }

  .modal-box {
    width: 90%;
    padding: 15px;
  }

  .add-new-edition {
    width: 95%;
    padding: 15px;
  }
}
