.eye {
  border: 4px solid black;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--background-light);
}

.pupil {
  border-radius: 50%;
  background-color: black;
  width: 80%;
  height: 80%;
  position: absolute;
  transform: translate(var(--pupilX), var(--pupilY));
}

.eye-light {
  border-radius: 50%;
  background-color: #fff;
  width: 30%;
  height: 30%;
  position: absolute;
  top: 15%;
  left: 55%;
}
