@font-face {
  font-family: jet-set;
  src: URL(../../fonts/JET-SET.ttf);
}
@font-face {
  font-family: regular-myriad;
  src: URL(../../fonts/myriad-pro-regular.ttf) format("truetype");
}

/* Variáveis dos Pixels */
$colors: #e74c3c, #3498db, #2ecc71, #f1c40f, #9b59b6; // lista de cores
$amount-of-pixels: 100; // quantidade de pixels que você quer renderizar

@keyframes pixelRise {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.vertical {
  display: flex;
  flex-direction: row;
  min-width: 95vw;
  min-height: 100vh;
  max-width: 95vw;
  max-height: 100vh;
  left: 5vw;
  z-index: 200;
  .img-right {
    height: auto;
    width: 20vw;
    float: right;
  }
  .vertical-left {
    padding: 0 3%;
    width: 20vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .menu-vertical-left {
      display: flex;
      flex-direction: column;
      font-family: "Contrail One", cursive;
      font-size: 1.4em;
      a {
        margin-top: 1.3em;
        text-decoration: none;
      }
      a:hover {
        transform: translateX(20px);
      }
    }
    .seta-pro-topo {
      width: 5vw;
      height: 5vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 2vh;
      cursor: pointer;
    }
  }
}

/* Páginas específicas */
.pagina-vertical-1 {
  .vertical-left {
    background-color: var(--verde);
    .menu-vertical-left {
      a {
        color: var(--preto);
      }
      a:hover {
        color: var(--rosa);
      }
    }
    .seta-pro-topo {
      background-image: url(./Assets/seta-verde.png);
    }
    .seta-pro-topo:hover {
      background-image: url(./Assets/seta-verde-hover.png);
    }
  }
}
.pagina-vertical-2 {
  .vertical-left {
    background-color: var(--rosa);
    .menu-vertical-left {
      a {
        color: var(--branco);
      }
      a:hover {
        color: var(--azul);
      }
    }
    .seta-pro-topo {
      background-image: url(./Assets/seta-rosa.png);
    }
    .seta-pro-topo:hover {
      background-image: url(./Assets/seta-rosa-hover.png);
    }
  }
}
.pagina-vertical-3 {
  .vertical-left {
    background-color: var(--azul);
    .menu-vertical-left {
      a {
        color: var(--preto);
      }
      a:hover {
        color: var(--roxo);
      }
    }
    .seta-pro-topo {
      background-image: url(./Assets/seta-azul.png);
    }
    .seta-pro-topo:hover {
      background-image: url(./Assets/seta-azul-hover.png);
    }
  }
  .celular {
    height: auto;
    width: 20vw;
    float: right;
  }
}
.vertical-right {
  display: flex;
  flex-direction: column;
  color: var(--branco);
  background-color: var(--roxo);
  width: 70vw;
  height: 100vh;
  overflow-y: scroll;
  .vertical-padding {
    padding: 0 5%;
    z-index: 9999;
  }
  .pixels {
    margin-top: 5vh;
    align-self: flex-end;
    width: 100%;
    height: auto;
  }
}

/* Título */
.vertical-title {
  margin: 5% 0;
  width: fit-content;
  height: max-content;
  padding: 2.5% 5%;
  font-size: 2em;
  font-family: jet-set;
  font-weight: bold;
  color: var(--roxo);
  margin-bottom: 20px;
}

/* Botão edição */
.edicao {
  background-color: var(--azul);
  margin: 5% 0 0 0;
  width: fit-content;
  height: max-content;
  border-radius: 5px;
  color: black;
  padding: 1.2% 2%;
  font-size: 1.5em;
  font-family: jet-set;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

/* Floats e Imagens */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.vertical-logo {
  width: 25vw;
  height: auto;
  padding-left: 5vw;
}
.vertical-camera {
  width: 25vw;
  height: auto;
  padding-left: 1vw;
}

.vertical-tv {
  padding-right: 5%;
  width: 20vw;
  height: auto;
}

.vertical-notas {
  padding-right: 5%;
  width: 20vw;
  height: auto;
}

.vertical-filme {
  height: 200px;
  width: auto;
}

/* Texto */
.vertical-text {
  font-family: regular-myriad, sans-serif;
  font-style: normal;
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: 0.15em;
  color: white;
}
.vertical-link {
  font-family: regular-myriad, sans-serif;
  font-style: normal;
  font-size: 1.5em;
  line-height: 32px;
  letter-spacing: 0.15em;
  color: white;
  cursor: pointer;
  margin-top: 30px;
}

/* Animação Slide */
.down {
  display: none;
}
.up {
  animation: slideUp 0s forwards;
  position: absolute;
}

/* Setas */
.area-seta-pra-baixo {
  transform: translateY(-10vh);
  height: 5vh;
  width: 100%;
  z-index: 300;
  position: absolute;
  display: flex;
  justify-content: center;
  .seta-pra-baixo {
    height: auto;
  }
}
.area-seta-pra-baixo:hover {
  transform: translateY(-10vh);
  height: 5vh;
  width: 100%;
  z-index: 300;
  position: absolute;
  display: flex;
  justify-content: center;
  .seta-pra-baixo {
    height: auto;
  }
}

/* Grid Imagens */
.image-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  justify-items: center;
  align-items: center;
  margin: 0 auto;
}
.image-wrapper {
  margin: 10px;
  text-align: center;

  img {
    display: block;
    margin: 0 auto;
  }

  .image-text {
    color: #5c1c74;
    font-weight: bold;
    font-family: jet-set;
    cursor: pointer;
    background-color: var(--azul);
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--azul);
      color: #fff;
    }
  }
}

.info {
  font-family: "regular-myriad";
  line-height: 1.6;
  margin: 20px 0;
  p {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .signature {
    margin-top: 20px;
    h3 {
      margin-top: 15px;
      color: var(--azul);
    }
    p {
      margin: 5px 0;
    }
    .team-section {
      display: flex;
      flex-direction: column;
    }
  }
  .toggle-button {
    cursor: pointer;
    color: var(--azul);
    text-decoration: underline;
    margin: 10px 0;
  }
}

/* Pesquisa */
.vertical-pesquisa {
  width: 90vw !important;
}

/* Film marquee */
.film-marquee-container {
  height: 200px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.vertical-coracoes {
  height: auto;
  width: 15vw;
}

/* BG branco */
.bg-branco {
  background-color: white;
}

/* Texto edição */
.main-edition-text {
  font-size: 17px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 500;
  font-family: regular-myriad, sans-serif;
}

.signature-title {
  color: var(--azul);
}

.signatures-name {
  color: white;
}

.toggle-button-current {
  transform: scale(1.5);
  border: none;
  color: var(--azul);
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  margin-top: 10px;
}

/* Container Pixels */
.pixels-container {
  width: 100%;
  height: 100px;
  padding-top: 200px;
  position: relative;
  overflow: hidden;
  z-index: 9999; /* Garantir que fique por cima */
}
@for $i from 1 through $amount-of-pixels {
  .pixel:nth-child(#{$i}) {
    position: absolute;
    bottom: 0;
    width: 25px;
    height: 25px;
    background-color: nth($colors, random(length($colors)));
    animation: pixelRise 3s infinite ease-in;
    animation-fill-mode: forwards;
    animation-delay: random(3000) * 1ms;
  }
}

/* Edition */
.edition-container {
  width: 100%;
  position: relative;
}

.slider-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 36px;
}
.slider-container {
  display: flex;
  transition: transform 0.5s ease;
  margin-top: 50px;
}
.filme-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  flex-shrink: 0;
  box-sizing: border-box;
}
.filme-preto {
  position: relative;
  width: 100%;
  display: block;
  z-index: 10;
  pointer-events: none;
}
.imagem-posicionada-edition {
  cursor: pointer;
  position: absolute;
  width: 24.2%;
  height: 73%;
  top: 13.2%;
  z-index: 5;
  object-fit: cover;
}
.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 20;

  &.left {
    left: 10px;
  }

  &.right {
    right: 10px;
  }
}
.titulo-superior {
  position: absolute;
  top: -10px;
  width: 24.5%;
  text-align: center;
  padding: 5px;
  color: #e0ff04;
  font-weight: 500;
  z-index: 15;
}
.titulo-inferior {
  position: absolute;
  bottom: -6px;
  width: 24.5%;
  text-align: center;
  padding: 5px;
  color: #e0ff04;
  font-weight: 500;
  z-index: 15;
}

.edition-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px 0;
  padding: 0 20px;
}

.edition-info {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  &.expanded {
    max-height: 1000px;
  }

  p {
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 17px;
    font-weight: 500;
    font-family: regular-myriad, sans-serif;
  }
}
.signatures h3 {
  color: var(--azul);
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 5px;
}
.signatures p {
  color: var(--branco);
  font-size: 16px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.toggle-button {
  background-color: var(--azul);
  color: var(--preto);
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-family: jet-set;
  text-decoration: none;
  margin-top: 10px;

  &:hover {
    color: var(--branco);
  }
}
.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--azul);
  color: var(--preto);
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  font-size: 16px;
  font-family: jet-set;

  &:hover {
    color: var(--branco);
  }
}

/* Modal */
.modal-title-container {
  flex: 1;
  display: flex;
  flex-direction: column; /* Altera para coluna */
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  text-align: center; /* Centraliza o texto */

  .photo-title {
    font-size: 2.5em; /* Título maior */
    font-weight: bold;
    color: white;
    margin-bottom: 10px; /* Espaçamento entre título e autor */
  }

  .author-title {
    font-size: 1.5em; /* Autor menor */
    color: white;
    padding: 10px;
    max-width: 300px;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.animate-image {
  animation: flyIn 1s forwards;
  opacity: 0;
}
.gallery-container {
  transition: opacity 0.5s;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes flyIn {
  0% {
    transform: translate3d(var(--initialX), var(--initialY), -200px) rotateY(180deg);
    filter: invert(100%);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0) rotateY(0deg);
    filter: none;
    opacity: 1;
  }
}
.titulo-superior,
.titulo-inferior {
  animation: fadeInUp 0.5s forwards;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.animate-image {
  will-change: transform, opacity, filter;
}
.gallery-container {
  max-height: 80vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

/* Ajustes Mobile */
@media only screen and (max-width: 768px) {
  /* Ajustar aqui para o vertical não esconder o pixels-container */
  .vertical {
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    min-width: 100vw;
    max-width: 100vw;
    /* Antes estava overflow-y: hidden; Ajustar para permitir ver a pixels-container */
    overflow-y: visible; 
    height: 100vh;
    align-items: center;
  }
  .vertical-right {
    width: 100vw;
    height: 100vh;
    overflow: visible;
    padding: 0;
    align-items: center;
    z-index: 100;
  }
  .vertical-padding {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding: 50px;
    margin-top: 20%;
    overscroll-behavior: contain;
  }
  .vertical-title {
    text-align: center;
    width: 100%;
    font-size: 1.6em;
    padding: 15px;
    margin-bottom: 45px;
    margin-top: 35px;
  }
  .vertical-text {
    font-family: "regular-myriad", sans-serif;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.6;
    letter-spacing: 0.02em;
    padding: 0 15px;
    margin: 10px 0;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    hyphens: auto;
  }

  .float-left,
  .float-right,
  .img-right {
    width: 35vw !important;
    float: none !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vw;
  }

  .images-align-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .seta-pro-topo-mobile {
    background-image: url(./Assets/seta-rosa.png);
    width: 25vw;
    height: 25vw;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  h3 {
    font-size: 1em;
  }

  .filme-container {
    width: 100%;
    margin: 0 auto;
  }

  .filme-preto {
    width: 100%;
  }

  .imagem-posicionada-edition {
    width: 80%;
    height: auto;
    top: 10%;
    left: 10%;
    right: 10%;
    position: absolute;
  }

  .slider-button {
    padding: 8px;
    font-size: 1.5rem;
    background-color: rgba(0, 0, 0, 0.7);

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }
  }

  .titulo-superior,
  .titulo-inferior {
    width: 80%;
    left: 10%;
    font-size: 1em;
    padding: 5px;
    top: -5px;
  }
  .titulo-inferior {
    bottom: -5px;
  }

  .edition-info-container {
    padding: 0 10px;
    align-items: center;
  }

  .edition-info {
    max-height: none;
    overflow: visible;

    p {
      font-size: 1em;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .signatures h3 {
    text-align: center;
    font-size: 1.05em;
    margin-bottom: 5px;
  }
  .signatures p {
    font-size: 1em;
  }

  .buttons-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .toggle-button,
  .back-button {
    width: 100%;
    font-size: 1em;
    padding: 10px;
    margin-top: 10px;
  }

  .modal-content {
    max-width: 95vw;
    max-height: 90vh;
    width: 95vw;
    height: auto;
  }
  .modal-image {
    max-width: 80vh;
    max-height: 50vh;
  }
  .modal-close-button {
    font-size: 30px;
    top: 5px;
    right: 5px;
  }
  .modal-nav-button {
    font-size: 1.5rem;
    padding: 8px;

    &.left {
      left: 10px;
    }

    &.right {
      right: 10px;
    }
  }

  .author-title {
    font-size: 1em;
    padding: 5px;
    margin-left: 0;
    max-width: 80%;
  }
  .photo-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .gallery-container {
    max-height: 60vh;
  }

  .filme-container img {
    width: 100%;
    height: auto;
  }

  /* Ajustar pixels no mobile */
  .pixels-container {
    height: 50px !important;
    padding-top: 50px !important;
  }
  .pixel {
    width: 10px !important;
    height: 10px !important;
    animation: pixelRise 4s infinite cubic-bezier(0.25, 1, 0.5, 1);
    animation-delay: random(2000) * 1ms;
  }
  .hide-on-mobile {
    display: none !important;
  }


  .image-container {
    grid-template-columns: repeat(2, 1fr); /* Reduz para 2 colunas */
    grid-template-rows: repeat(auto-fit, minmax(100px, auto)); /* Ajusta as linhas automaticamente */
    gap: 5px; /* Reduz o espaçamento entre os itens */
    padding: 10px; /* Adiciona um padding para espaçamento interno */
  }

  .image-wrapper {
    margin: 5px; /* Reduz a margem dos wrappers */
    width: 100%; /* Garante que os wrappers ocupem toda a largura da coluna */
  }

  .image-wrapper img {
    width: 100%; /* Imagens responsivas */
    height: auto;
    object-fit: cover; /* Mantém a proporção das imagens */
    border-radius: 5px; /* Opcional: adiciona bordas arredondadas */
  }

  .image-text {
    font-size: 4vw; /* Ajusta o tamanho da fonte para telas menores */
    padding: 5px; /* Reduz o padding */
  }
}


/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    position: relative; /* Adicionado */
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    max-width: 80vw;
    max-height: 90vh;
    width: 80vw;
    height: 90vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .modal-close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: transparent;
      color: #fff;
      font-size: 40px;
      border: none;
      cursor: pointer;
      z-index: 2;

      &:hover {
        color: #ccc;
      }
    }

    .modal-image-title-container {
      display: flex; /* Adicionado */
      justify-content: center; /* Mantido */
      align-items: center; /* Mantido */
      width: 100%;
      height: 100%;

      .modal-image-container {
        flex: 0 0 auto; /* Adicionado */
        margin-right: 20px; /* Adicionado */

        .modal-image {
          max-width: 70%; /* Ajuste conforme necessário */
          max-height: 80%;
          object-fit: contain;
          border-radius: 5px;
        }
      }

      .modal-title-container {
        flex: 1;
        display: flex;
        flex-direction: column; /* Altera para coluna */
        justify-content: center; /* Centraliza verticalmente */
        align-items: center; /* Centraliza horizontalmente */
        text-align: center; /* Centraliza o texto */
      
        .photo-title {
          font-size: 2.5em; /* Título maior */
          font-weight: bold;
          color: white;
          margin-bottom: 10px; /* Espaçamento entre título e autor */
        }
      
        .author-title {
          font-size: 1.5em; /* Autor menor */
          color: white;
          padding: 10px;
          max-width: 300px;
          word-wrap: break-word;
          word-break: break-word;
        }
      }
    }

    .modal-nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      border: none;
      font-size: 2rem;
      padding: 10px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 2;

      &.left {
        left: 20px; /* Posição fixa à esquerda */
      }

      &.right {
        right: 20px; /* Posição fixa à direita */
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
}


.modal-image-title-container {
  display: flex;
  flex-direction: row; /* Alinha horizontalmente */
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px; /* Adiciona espaçamento interno */

  @media only screen and (max-width: 1024px) {
    flex-direction: column; /* Alinha verticalmente em telas menores */
    padding: 10px;
  }

  .modal-image-container {
    flex: 0 0 60%; /* Define largura fixa para a imagem */
    max-width: 60%;
    margin-right: 20px; /* Espaçamento entre imagem e título */

    @media only screen and (max-width: 1024px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 15px; /* Espaçamento abaixo da imagem */
    }

    .modal-image {
      width: 100%;
      height: auto;
      object-fit: contain; /* Mantém a proporção da imagem */
      border-radius: 5px;
      max-height: 80vh; /* Limita a altura máxima para evitar overflow */
    }
  }

  .modal-title-container {
    flex: 1; /* Título ocupa o espaço restante */
    display: flex;
    flex-direction: column; /* Alinha título e autor em coluna */
    justify-content: center; /* Centraliza verticalmente */
    align-items: center; /* Centraliza horizontalmente */
    max-width: 40%; /* Limita a largura do título */

    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      justify-content: center; /* Centraliza o título em telas menores */
    }

    .photo-title {
      font-size: 2.5em; /* Título maior */
      font-weight: bold;
      color: white;
      margin-bottom: 10px; /* Espaçamento entre título e autor */
    }

    .author-title {
      font-size: 1.5em; /* Autor menor */
      color: white;
      padding: 10px;
      word-wrap: break-word;
      word-break: break-word;
      max-width: 100%; /* Garante que o título não ultrapasse o contêiner */
    }
  }
}