.password-recovery {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centralizar conteúdo na horizontal */
  justify-content: center; /* Centralizar conteúdo na vertical */
  height: 100vh; /* Tornar o container do formulário de recuperação de senha em tela cheia */

  h2 {
    font-size: 20px;
    padding-bottom: 10px;
    color: #162643;
  }

  form {
    max-width: 300px; /* Definir largura máxima do formulário */
    width: 100%; /* Garantir que o formulário ocupe toda a largura da caixa */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;

    label {
      margin-bottom: 5px;
    }

    input {
      width: 100%; /* Definir largura do input para preencher a largura do formulário */
      margin-bottom: 10px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 3px;
      font-size: 16px;
    }

    button {
      width: 100%;
      padding: 10px;
      border: none;
      background-color: #007bff;
      color: white;
      font-weight: bold;
      font-size: 16px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #0056b3;
      }
  
      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
      }
    }
  }
}

.return-button {
  margin-top: 10px; 
  width: 100%; 
  text-align: center;
  
  a {
    color: #007bff; 
    text-decoration: none; 
    display: inline-block; 
    margin-top: 10px; 
    transition: color 0.2s; 
    
    &:hover {
      color: #0056b3; 
    }
  }
}

