/* ManageUsers.scss */
.manage-users-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-light);
  padding: 20px;
}

.manage-users-card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;

  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px; /* Ajuste para melhor legibilidade */
  }

  input[type="text"] {
    width: 100%; /* Ajustado para ocupar toda a largura */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: #f8f9fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; /* Permite que o conteúdo quebre linha em telas menores */

      &:hover {
        background-color: #e9ecef;
      }

      .user-info {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 200px; /* Garantir espaço mínimo para o nome */

        .user-name {
          margin-right: 10px;
          color: #333;
        }

        .role-display {
          background-color: #e2e3e5;
          padding: 5px 10px;
          border-radius: 4px;
          font-weight: bold;
          color: #495057;
        }
      }

      .action-icons {
        display: flex;
        gap: 10px;
        margin-top: 10px; /* Espaçamento superior para dispositivos móveis */
      }

      button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px; /* Aumentado para melhor usabilidade em dispositivos móveis */

        &.edit-button {
          background-color: #ffc107;
          color: #ffffff;

          &:hover {
            background-color: #e0a800;
          }
        }

        &.delete-button {
          background-color: #dc3545;
          color: #ffffff;

          &:hover {
            background-color: #c82333;
          }
        }
      }
    }
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .manage-users-card {
    padding: 20px;

    h1 {
      font-size: 20px; /* Reduzido para caber melhor em telas menores */
    }

    input[type="text"] {
      width: 100%; /* Ocupa toda a largura disponível */
    }

    ul li {
      flex-direction: column; /* Empilha os elementos verticalmente */
      align-items: flex-start; /* Alinha os itens à esquerda */
    }

    .user-info {
      margin-bottom: 10px; /* Espaçamento entre nome e botões */
    }

    .action-icons {
      width: 100%;
      justify-content: flex-start; /* Alinha botões à esquerda */
      margin-top: 0; /* Remove espaçamento superior extra */
    }

    button {
      width: 48%; /* Opcional: largura para botões lado a lado */
      margin-right: 2%; /* Espaçamento entre botões */

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
