// AdminHomePage.scss
.admin-home-page {
  background-color: var(--background-light);
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    max-width: 800px;
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;

    h1 {
      margin-bottom: 20px;
      color: #333;
    }

    p {
      color: #666;
      line-height: 1.6;
    }
  }
}

/* Media Query para dispositivos móveis */
@media (max-width: 768px) {
  .admin-home-page .content {

    margin-top: 80px;
    max-width: 90%;
    padding: 15px;

    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 1em;
    }
  }
}
