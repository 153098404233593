/* ManageEditions.scss */
.manage-editions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-light);
  padding: 20px;
}

.manage-editions-card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  .button-group {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .send-content-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #28a745; // Cor verde para diferenciar
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  
    &:hover {
      background-color: #218838;
    }
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* Centraliza o título */
    font-size: 24px;    /* Ajuste para melhor legibilidade */
  }

  input[type="text"] {
    width: 100%; /* Ajustado para ocupar toda a largura */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;

    &:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: #f8f9fa;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; /* Permite que o conteúdo quebre linha em telas menores */

      &:hover {
        background-color: #e9ecef;
      }

      .edition-info {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 200px; /* Garante espaço mínimo para o título */

        .edition-title {
          color: #333;
          margin-right: 10px;
        }
      }

      .action-icons {
        display: flex;
        gap: 10px;
        margin-top: 10px; /* Espaçamento superior para dispositivos móveis */
      }

      button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px; /* Aumentado para melhor usabilidade em dispositivos móveis */

        &.edit-button {
          background-color: #ffc107;
          color: #ffffff;

          &:hover {
            background-color: #e0a800;
          }
        }

        &.delete-button {
          background-color: #dc3545;
          color: #ffffff;

          &:hover {
            background-color: #c82333;
          }
        }
      }
    }
  }
}

.add-edition-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
}


/* Responsividade */
@media (max-width: 768px) {
  .manage-editions-card {
    margin-top: 70px;
    padding: 20px;

    h1 {
      font-size: 20px; /* Reduzido para caber melhor em telas menores */
    }

    input[type="text"] {
      width: 100%; /* Ocupa toda a largura disponível */
    }

    ul li {
      flex-direction: column; /* Empilha os elementos verticalmente */
      align-items: flex-start; /* Alinha os itens à esquerda */
    }

    .edition-info {
      margin-bottom: 10px; /* Espaçamento entre título e botões */
    }

    .action-icons {
      width: 100%;
      justify-content: flex-start; /* Alinha botões à esquerda */
      margin-top: 0; /* Remove espaçamento superior extra */
    }

    .action-icons button {
      width: 48%; /* Opcional: largura para botões lado a lado */
      margin-right: 2%; /* Espaçamento entre botões */

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

/* ManageEditions.scss */

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .add-edition-button,
  .send-content-button,
  .toggle-component-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 4px;
    font-size: 14px;
    
    /* Estilos específicos para cada botão */
    &.add-edition-button {
      background-color: #4caf50;
      color: white;
    }

    &.send-content-button {
      background-color: #2196f3;
      color: white;
    }

    &.toggle-component-button {
      background-color: #f50057;
      color: white;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.additional-component {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
