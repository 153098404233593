.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-light);
  padding: 20px; /* Adicionado para evitar que o conteúdo encoste nas bordas em dispositivos móveis */
}

.logo {
  width: 150px;
  margin-bottom: 30px;
}

.login-card {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.login-card form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.login-card .input-wrapper {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.login-card .input-wrapper label {
  margin-bottom: 5px;
  color: #666;
}

.login-card .input-wrapper input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-card .input-wrapper input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.login-card button {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-card button:hover {
  background-color: #0056b3;
}

.login-card button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-card .forgot-password-div {
  margin-top: 10px; 
  width: 100%; 
  text-align: center;
}

.login-card .forgot-password-div a {
  color: #007bff; 
  text-decoration: none; 
  display: inline-block; 
  margin-top: 10px; 
  transition: color 0.2s; 
}

.login-card .forgot-password-div a:hover {
  color: #0056b3; 
}

/* Media Query para dispositivos móveis */
@media (max-width: 768px) {
  .login-container {
    padding: 10px;
  }

  .logo {
    width: 120px; /* Reduz o tamanho do logo em telas menores */
    margin-bottom: 20px;
  }

  .login-card {
    padding: 20px;
  }

  .login-card .input-wrapper,
  .login-card button {
    width: 100%;
  }

  .login-card button {
    padding: 10px;
    font-size: 14px;
  }
}
