@font-face {
  font-family: regular-myriad;
  src: URL(../../fonts/myriad-pro-regular.ttf) format("truetype");
}

.result-card {
  cursor: pointer;
  height: 60vh;
  width: 20vw;
  padding: 3%;
  background-color: var(--verde);
  color: var(--preto);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .result-text {
    background-color: var(--branco);
    font-weight: 600;
    padding: 10%;
    width: 100%;
    height: 60%;
    overflow: hidden;
  }

  .result-title {
    font-family: regular-myriad, sans-serif;
    align-self: flex-start;

    p:first-of-type {
      font-size: large;
      font-weight: 500;
      letter-spacing: 0.05em;
    }
  }
}

.vertical-pesquisa {
  display: flex;
  flex-direction: column;
  padding: 5vh 13vw;

  .vertical-pesquisa-head {
    padding-top: 10vh;
    padding-bottom: 5vh;
  }

  .vertical-pesquisa-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2vw;
  }
}

.pesquisa-up {
  animation: slidePesquisaUp 3s forwards;
  position: absolute;
  z-index: 200;
}

/*              ANIMACOES              */

@keyframes slidePesquisaUp {
  0% {
    transform: translateY(-400vh);
    overflow: hidden;
  }

  100% {
    transform: translateY(0);
    overflow: hidden;
  }
}

.pagination .MuiPaginationItem-root {
  color: white;
}

.pagination .MuiPaginationItem-page.Mui-selected {
  background-color: white;
  color: var(--roxo);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .result-card {
    height: 40vh;
    width: 100%;
  }

  .vertical-pesquisa {
    width: 100%;
    padding: 5vh 5vw;

    .vertical-pesquisa-head {
      font-size: 1.2rem; // Smaller text
    }

    .vertical-pesquisa-body {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      max-height: 60vh;
      overflow-y: scroll; // Mantém o scroll funcional
      padding-right: 3px; // Compensa o espaço da barra de scroll
      box-sizing: content-box; // Garante que o padding não afete o tamanho
      scrollbar-width: none; // Oculta no Firefox
      
    }

    .vertical-pesquisa-body::-webkit-scrollbar {
      display: none; // Oculta no Chrome, Safari e Edge
    }
  }
  .pesquisa-up{
    background-color: aqua;
  }
}