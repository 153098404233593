.subnavs {
    font-family: 'Contrail One', cursive;
    font-size: 1.175em;
    position: fixed;
    bottom: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 500;
}

.drop-1 {
    position: fixed;
    top: 11.3vh;
}

.drop-2 {
    position: fixed;
    top: calc(41.3vh);
    left: 5vw;
}

.drop-3 {
    position: fixed;
    bottom: 0;
    left: 5vw;
}

.dropdown {
    transition-delay: 2s;
    transition: transform .5s cubic-bezier(0, .52, 0, 1);
    width: 20vw;
}

.sub {
    display: flex;
    background-color: var(--branco);
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.sub a {
    padding: 15px 15px;
    text-decoration: none;
    color: var(--preto);
}

.sub a:hover {
    cursor: pointer;
    padding-left: 30px;
}

.sub-rosa a:hover {
    color: var(--branco);
    background-color: var(--rosa);
}

.sub-azul a:hover {
    color: var(--preto);
    background-color: var(--azul);
}

.sub-roxo a:hover {
    color: var(--branco);
    background-color: var(--roxo);
}

.sub:after {
    transition: all .35s;
}