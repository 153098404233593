.no-underline {
  text-decoration: none;
  color: black;
}

.sidenav-direita-icons {
  height: 100vh;
  width: 5vw;
  position: fixed;
  //color: var(--branco);
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  background-color: transparent;
  z-index: 210;
}

.sidenav-direita-bg {
  height: 100vh;
  width: 5vw;
  position: fixed;
  //color: var(--branco);
  z-index: 90;
  top: 0;
  right: 0;
}

.style-rosa {
  background-image: url(./Assets/IconesLupaRosa.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
}

.style-azul {
  background-image: url(./Assets/IconesLupaAzul.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
}

.style-roxo {
  background-image: url(./Assets/IconesLupaRoxo.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
}

.style-verde {
  background-image: url(./Assets/IconesLupaVerde.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
}

.style-preto {
  background-image: url(./Assets/IconesLupaPreto.png);
  background-size: contain;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
}

.style-rosa-inf {
  background-image: url(./Assets/IconesTeleRosa.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.style-azul-inf {
  background-image: url(./Assets/IconesTeleAzul.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.style-roxo-inf {
  background-image: url(./Assets/IconesTeleRoxo.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.style-verde-inf {
  background-image: url(./Assets/IconesTeleVerde.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.style-preto-inf {
  background-image: url(./Assets/IconesTelePreto.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.icon {
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 210;
}

.icon-superior {
  margin-top: 15%;
  margin-left: 10%;
  height: 8vh;
}

.arrow-icon {
  margin-left: 20%;
  height: 10vh;
  width: auto;
  background-image: url(./Assets/seta.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow-icon-black {
  background-image: url(./Assets/seta-preto.png);
}

.icon-inferior {
  transform: translateX(8px);
  margin-bottom: 15%;
  height: 8vh;
  width: 124%;
}

.sliding-sidebar-div {
  caret-color: black !important;
  width: 300px;
  height: 5vh;
  overflow: auto !important;
  background-color: white;
  color: black;
  z-index: 205;
  text-align: left;
  font-family: "Contrail One", cursive;
  font-weight: 400;
  display: flex;
  align-items: center;
  border-radius: 35px;
  cursor: pointer;
}

.me-liga-div {
  position: absolute;
  bottom: 2.5vh;
  padding-left: 35%;
}

.me-liga-div:hover {
  color: var(--rosa);
}

.me-pesquisa-div {
  position: absolute;
  top: 2.5vh;
  padding-left: 15%;
  padding-bottom: 5%;

  input {
    border: none;
  }

  input:focus {
    outline: none !important;
  }
}

.me-liga-on {
  animation-delay: 1.8s;
  animation: me-liga-slide 2s forwards;
}

.me-liga-off {
  animation-delay: 3s;
  animation: me-liga-slide-off 2s forwards;
}

.me-pesquisa-on {
  animation-delay: 1.8s;
  animation: me-pesquisa-slide 2s forwards;
}

.me-pesquisa-off {
  animation-delay: 3s;
  animation: me-pesquisa-slide-off 2s forwards;
}

.border-rosa {
  border: 2px solid var(--rosa);
}

.border-azul {
  border: 2px solid var(--azul);
}

.border-roxo {
  border: 2px solid var(--roxo);
}

.border-verde {
  border: 2px solid var(--verde);
}

.border-preto {
  border: 2px solid var(--preto);
}

@keyframes me-liga-slide {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(-100px);
  }
}

@keyframes me-liga-slide-off {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(100px);
  }
}

@keyframes me-pesquisa-slide {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(-175px);
  }
}

@keyframes me-pesquisa-slide-off {
  0% {
    transform: translateX(-175px);
  }

  100% {
    transform: translateX(100px);
  }
}

@media only screen and (max-width: 768px) {

  .sidenav-direita-bg{
    display: none;
  }
  .sidenav-direita-icons{
    display: none;
  }
  
}
