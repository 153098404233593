@keyframes pixelRiseMobile {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}

.pixels-container-mobile {
  width: 100%;
  height: 100px;
  padding-top: 200px;
  position: relative;
  overflow: hidden;
  z-index: 9999; // garantir visibilidade no mobile
}

.pixel {
  position: absolute;
  bottom: 0;
  width: 25px;
  height: 25px;
  animation: pixelRiseMobile 3s infinite ease-in;
  animation-fill-mode: forwards;
}
