* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Paginas {
  text-align: center;
  width: 100vw;
  height: 100vw;
  transform: rotate(-90deg) translateX(-100vh);
  transform-origin: top left;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.Container {
  width: 300vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  z-index: 10;
}
.hide {
  display: none;
}

h1 {
  margin: 0;
}

/* Para dispositivos móveis - remove a rotação */
@media (max-width: 768px) {
  .Paginas {
    position: relative;
    width: 100vw;
    height: 100vh;
    transform: none; /* Remove a rotação */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .Container {
    width: 100vw;
    height: 300vh;
    flex-direction: column;
    transform: none; /* Remove a rotação */
  }
}