//* TextEditor.scss */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
   /* Adicionado para evitar que o conteúdo encoste nas bordas em dispositivos móveis */
}

.text-editor {
  background-color: #ffffff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;

  .title {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-size: 24px; /* Ajustado para melhor legibilidade */
  }

  .select-wrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Permite que os elementos se ajustem em telas menores */

    label {
      margin-right: 10px;
      color: #666;
    }

    select {
      padding: 5px 10px;
      border: 1px solid #ccc;
      border-radius: 4px;

      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
      }
    }
  }

  #editor-container {
    margin-bottom: 20px;
    height: 300px;

    .ql-toolbar {
      border-radius: 4px 4px 0 0;
    }

    .ql-container {
      border: 1px solid #ccc;
      border-top: none;
      border-radius: 0 0 4px 4px;
      height: 100%;
    }
  }

  .save-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 16px; /* Aumentado para melhor usabilidade em dispositivos móveis */

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
}

.ql-editor {
  caret-color: black; /* Define a cor do cursor */
}

/* Responsividade */
@media (max-width: 768px) {
  .text-editor {
    width: 95%; /* Ajustado para ocupar mais espaço em telas menores */
    padding: 15px; /* Reduzido para otimizar o espaço */
  }

  .title {
    font-size: 20px; /* Reduzido para caber melhor em telas menores */
  }

  .select-wrapper {
    flex-direction: column; /* Altera para coluna em dispositivos móveis */
    align-items: flex-start;

    label {
      margin-bottom: 5px; /* Ajusta o espaçamento */
      margin-right: 0;
    }

    select {
      width: 100%; /* Ocupa toda a largura disponível */
    }
  }

  #editor-container {
    height: auto; /* Permite que a altura se ajuste automaticamente */
  }

  .ql-container {
    height: 200px; /* Reduz a altura para melhor visualização em telas menores */
  }

  .save-button {
    padding: 12px; /* Aumenta a área de toque */
    font-size: 18px; /* Aumenta o tamanho da fonte para melhor legibilidade */
  }
}
