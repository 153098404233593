.image-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);  // 4 colunas, como no layout da imagem
  grid-gap: 15px;  // Espaço entre os itens
  background-color: #5c1c74;  // Cor de fundo roxa
  margin: 0 auto;
}

.image-wrapper {
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  max-width: 200px;  // Limita o tamanho da imagem
}

.image-text {
  background-color: #32a8e6;  // Fundo azul claro para o texto
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-text:hover {
  background-color: #2a82b5;  // Efeito de hover para o texto
}

@media (max-width: 768px) {
  .image-container {
    grid-template-columns: repeat(2, 1fr);  // 2 colunas em telas menores
  }
}

@media (max-width: 480px) {
  .image-container {
    grid-template-columns: 1fr;  // 1 coluna em telas muito pequenas
  }
}
